
@import "~bulma/bulma.sass";

.is-closer {
  z-index: 100;
  text-shadow: 4px 3px 10px #1c2b34;
  padding-top: 4rem; }

.historycard {}

@include mobile {
  .historycard {
      border-bottom: 1px solid rgba(219, 219, 219, 0.5);
      margin-bottom: 1rem;
      padding-bottom: 1rem; }

  .is-closer {
    padding-top: 0rem; } }


@include mobile {
  .is-faded {
    opacity: 0.2; } }


